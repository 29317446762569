.appBackground {
  background: #f4f4f4;
}

.posAbsolute {
  position: absolute;
}
.posFixed {
  position: fixed;
}
.posRelative {
  position: relative;
}
.posSticky {
  position: sticky;
}

.topM20px {
  top: 20px;
}
.top80px {
  top: 80px;
}
.top50 {
  top: 50%;
}

.left50 {
  left: 50%;
}

.displayBlock {
  display: block;
}

.colView {
  display: flex;
  flex-direction: column;
}
.rowView {
  display: flex;
  align-items: center;
}
.viewCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}
.alignItemsFlexStart {
  align-items: flex-start;
}
.alignSelfFlexStart {
  align-self: flex-start;
}
.jcFlexStart {
  justify-content: flex-start;
}
.jcSpaceBetween {
  justify-content: space-between;
}
.jcSpaceAround {
  justify-content: space-around;
}
.flexDirectionCol {
  flex-direction: column;
}
.flexWrapWrap {
  flex-wrap: wrap;
}

.gap15px {
  gap: 15px;
}

.gridView6 {
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  grid-template-columns: repeat(6, 1fr);
}

.textAlignCenter {
  text-align: center;
}
.textDecoNone {
  text-decoration: none;
}

.inpContainer {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.inpBox {
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  transition: 0.1s;
  height: fit-content;
  padding: 10px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.inpBox::placeholder{
  font-size: 12px !important;
}
.inpBox.hideBorderBottom {
  border-bottom: none !important;
}
/* .inpBox.inp-b-bottom{
  border-bottom: 1px solid rgba(0,0,0,0.05);
} */

.containerViewDetails {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.containerViewDetails span {
  position: relative;
  font-size: 25px;
  font-weight: 600;
}
.containerViewDetails p {
  font-size: 12px;
  margin-top: 5px;
}

.containerViewDetails .containerOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.containerViewDetails .containerOptions div {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 5px;
  color: #5e23dc;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #e1def4;
}
.containerViewDetails .containerOptions div img {
  height: 8px;
  margin-left: 5px;
}
/* .containerViewTitle::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 2px;
  background: #ffc72c;
} */

.respWidth {
  max-width: 1064px !important;
}
.respWidth2 {
  width: 1170px !important;
}

.w100 {
  width: 100%;
}
.w90 {
  width: 90%;
}
.w2px {
  width: 2px;
}
.wFitContent {
  width: fit-content !important;
}
.w40px {
  width: 80px;
}
.w120px {
  width: 120px;
}
.resW400px {
  width: 400px;
}

.minheight100vh {
  min-height: 100vh;
}
.height100 {
  height: 100%;
}
.height12px {
  height: 12px;
}
.height15px {
  height: 15px;
}
.height25px {
  height: 25px;
}
.height30px {
  height: 30px;
}
.height70px {
  height: 70px;
}
.height100px {
  height: 100px;
}
.heightWidth15px {
  height: 15px;
  width: 15px;
}
.heightWidth23px {
  height: 23px;
  width: 23px;
}
.heightWidth35px {
  height: 35px;
  width: 35px;
}
.heightAutoWidthFitContent {
  height: auto;
  width: fit-content;
}
.height80pxWidth90px {
  height: 80px;
  width: 90px;
}

.mg0pxauto {
  margin: 0px auto;
}
.mg0px100px {
  margin: 0px 100px;
}
.mg5px {
  margin: 5px !important;
}
.mgT5px {
  margin-top: 5px !important;
}
.mgT10px {
  margin-top: 10px !important;
}
.mgT15px {
  margin-top: 15px !important;
}
.mgT20px {
  margin-top: 20px !important;
}
.mgT30px {
  margin-top: 30px !important;
}
.mgT40px {
  margin-top: 40px !important;
}
.mgT50px {
  margin-top: 50px !important;
}
.mgT70px {
  margin-top: 70px !important;
}

.mgB10px {
  margin-bottom: 10px !important;
}

.mgL5px {
  margin-left: 5px !important;
}
.mgL10px {
  margin-left: 10px !important;
}
.mgL20px {
  margin-left: 20px !important;
}
.mgR5px {
  margin-right: 5px !important;
}
.mgR10px {
  margin-right: 10px !important;
}

.respPadding10px {
  padding: 0px !important;
}
.pd5px {
  padding: 5px;
}
.pd10px {
  padding: 10px;
}
.pd15px {
  padding: 15px;
}
.pd20px {
  padding: 20px;
}
.pd5px10px {
  padding: 5px 10px;
}
.pd10px0px {
  padding: 10px 0px;
}
.pd15px0px {
  padding: 15px 0px;
}
.pd5px15px {
  padding: 5px 15px;
}
.pd8px12px {
  padding: 8px 12px;
}
.pd10px15px {
  padding: 10px 15px;
}
.pd10px30px {
  padding: 10px 30px;
}
.pd15px30px {
  padding: 15px 30px;
}
.pd20px15px {
  padding: 20px 15px;
}

.fontSize10px {
  font-size: 10px;
}
.fontSize12px {
  font-size: 12px;
}
.fontSize13px {
  font-size: 13px;
}
.fontSize14px {
  font-size: 14px;
}
.fontSize15px {
  font-size: 15px !important;
}
.fontSize16px {
  font-size: 16px !important;
}
.fontSize18px {
  font-size: 18px !important;
}
.fontSize20px {
  font-size: 20px;
}
.fontSize22px {
  font-size: 23px;
}
.fontSize25px {
  font-size: 25px;
}
.fontSize26px {
  font-size: 26px;
}

.fontWgtNormal {
  font-weight: normal !important;
}
.fontWgt500 {
  font-weight: 500;
}
.fontWgt600 {
  font-weight: 600;
}

.lineHeight24px {
  line-height: 24px;
}

.border2pxSolidBlue {
  border: 2px solid #5e23dc;
}
.border1pxSolidLightBlack {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.borderBottom1px {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.borderRadius5px {
  border-radius: 5px;
}
.borderRadius10px {
  border-radius: 10px;
}
.borderRadius15px {
  border-radius: 15px;
}
.borderRadius50 {
  border-radius: 50%;
}

.colorWhite {
  color: #ffffff;
}
.colorLightWhite {
  color: rgba(255, 255, 255, 0.08);
}

.colorBlack {
  color: #000000;
}
.colorBlue {
  color: #5e23dc;
}
.colorRed {
  color: red;
}
.colorGrey {
  color: #ccc;
}

.bgWhite {
  background: #ffffff;
}
.bgGreen {
  background: #4fcb9e;
}
.bgOrange {
  background: #FFB74D;
}
.bgRed {
  background: #E74C3C;
}
.bgBlue {
  background: #5e23dc;
}
.bgLightGrey {
  background: rgba(0, 0, 0, 0.06);
}
.bgBlackGrey {
  background: rgba(0, 0, 0, 0.1);
}

/* custom background colors */
.bgCustomColor1 {
  background: #d8232a !important;
}
.bgCustomColor2 {
  background: #e1def4 !important;
}
.bgCustomColor3 {
  background: linear-gradient(180deg, #af93ea, #5d46db);
}
.bgCustomColor4 {
  color: rgb(54, 201, 145);
  background: rgb(213, 243, 232);
}
.bgCustomColor5{
  background: #fffceb;
  border: 1px solid #ffe01f;
}

/* opacity */
.opacity0p7{
  opacity: 0.7;
}

.transformXY50 {
  transform: translate(-50%, -50%);
}

.overflowHidden {
  overflow: hidden !important;
}
.cursorPointer{
  cursor: pointer;
}
.hideView {
  display: none !important;
}

@media (max-width: 1200px) {
  .respWidth2 {
    width: 100% !important;
  }
}

@media (max-width: 1000px) {
  .respWidth {
    width: 100% !important;
  }
  .respPadding10px {
    padding: 10px !important;
  }
}

@media (max-width: 700px) {
  .gridView6 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 550px) {
  .containerViewDetails {
    flex-direction: column;
  }

  .containerViewDetails .containerOptions {
    justify-content: flex-start;
    margin-top: 15px;
  }
}

@media (max-width: 450px) {
  .resW400px {
    width: 90%;
  }
}
