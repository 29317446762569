.dialogView{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: scroll;
    background: rgba(0,0,0,0.7);
}
.dialogViewHide{
    display: none !important;
}
.dialogContainer{
    width: 70%;
    margin: 15px 0;
}
.dialogContainer .dismissBtn{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #FFFFFF;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialogContainer img{
    width: 100%;
}

@media (max-width: 500px) {
    .dialogContainer{
        width: 90%;
    }
}